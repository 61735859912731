import APIService from './APIService';

export default {
  getTestesAneelAtivos() {
    return APIService.apiCall().get('validacoes-samp/get-testes-aneel-ativos');
  },
  getResultadoTeste(bdgdVersaoId, testeId, postData) {
    return APIService.apiCall().post(
      `validacoes-samp/get-resultado-teste/${bdgdVersaoId}/${testeId}`,
      JSON.stringify(postData)
    );
  },
  getParametrosExtrasTeste(bdgdVersaoId, testeId) {
    return APIService.apiCall().get(
      `validacoes-samp/get-parametros-extras-teste/${bdgdVersaoId}/${testeId}`
    );
  },
  executarTestesSamp(bdgdVersaoId, postData) {
    return APIService.apiCall().post(
      `validacoes-samp/executar-testes-samp/${bdgdVersaoId}`,
      JSON.stringify(postData)
    );
  },
  baixarResultadoTeste(bdgdVersaoId, testeId, postData, config) {
    return APIService.apiCall().post(
      `/validacoes-samp/baixar-resultado-teste/${bdgdVersaoId}/${testeId}`,
      JSON.stringify(postData),
      { responseType: 'blob', ...config }
    );
  },
  baixarResultadoCompletoTestes(bdgdVersaoId, config) {
    return APIService.apiCall().get(
      `/validacoes-samp/baixar-resultado-completo-testes/${bdgdVersaoId}`,
      { responseType: 'blob', ...config }
    );
  },
  getResumoImportacoesBasesSamp(bdgdVersaoId) {
    return APIService.apiCall().get(
      `validacoes-samp/get-resumo-importacoes-bases-samp/${bdgdVersaoId}`
    );
  },
  getResumoImportacoesBasesSigr(bdgdVersaoId) {
    return APIService.apiCall().get(
      `validacoes-samp/get-resumo-importacoes-bases-sigr/${bdgdVersaoId}`
    );
  },
  getResumoImportacoesBasesSisgd(bdgdVersaoId) {
    return APIService.apiCall().get(
      `validacoes-samp/get-resumo-importacoes-bases-sisgd/${bdgdVersaoId}`
    );
  },
  getResumoImportacoesBasesSiga() {
    return APIService.apiCall().get(
      'validacoes-samp/get-resumo-importacoes-bases-siga'
    );
  }
};
