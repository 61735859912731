<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>GRU_TEN</th>
              <th>STATUS</th>
              <th class="text-right">QTDE_UG_BDGD</th>
              <th class="text-right">QTDE_UG_BIG</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.gru_ten | toUpperCase }}</td>
              <td
                :class="{
                  'status-samp-ok':
                    item.status.trim().toUpperCase() === 'CONCILIADOS',
                  'status-samp-erro':
                    item.status.trim().toUpperCase() !== 'CONCILIADOS'
                }"
              >
                {{ item.status | toUpperCase }}
              </td>
              <td class="text-right">
                {{ item.qtd_ug_bdgd | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ item.qtd_ug_big | parseNumberToIntegerBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="4"></th>
            </tr>
            <tr>
              <th class="text-right">% CONCILIADO</th>
              <td
                class="text-right"
                style="font-size: 10px !important"
                :class="{
                  'status-samp-ok': percConciliado >= 100,
                  'status-samp-erro': percConciliado < 100
                }"
              >
                {{ percConciliado | parseNumberToFloatBR }}%
              </td>
              <td class="text-right">
                {{ totalQtdUgBdgd | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ totalQtdUgBig | parseNumberToIntegeBR }}
              </td>
            </tr>
            <tr>
              <th
                colspan="4"
                class="text-right"
              >
                <v-btn
                  small
                  title="Baixar o resultado"
                  outlined
                  @click="baixarResultadoTeste"
                  :loading="loadingBaixarResultadoTeste"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
export default {
  name: 'TesteCegSiga',
  mixins: [TabelaResultadoTesteSampMixins],
  computed: {
    totalQtdUgBdgd() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.qtd_ug_bdgd)),
        0
      );
    },
    totalQtdUgBig() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.qtd_ug_big)),
        0
      );
    },
    percConciliado() {
      if (this.totalQtdUgBdgd === 0) return 100;
      return (this.totalQtdUgBig / this.totalQtdUgBdgd) * 100;
    }
  }
};
</script>
