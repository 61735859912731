<template>
  <div>
    <v-row align="center">
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          :baseDados="viewResultado"
          :campos="camposViewResultado"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-10"
      align="center"
    >
      <v-col cols="2">
        <v-switch
          v-model="mesesComErros"
          hide-details
          inset
          label="Meses com erros"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          small
          title="Atualizar o resultado utilizando os filtros selecionados"
          outlined
          class="mr-2"
          @click="getResultadoTeste"
          :loading="loading"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn
          small
          title="Baixar o resultado utilizando os filtros selecionados"
          outlined
          @click="baixarResultadoTeste"
          :loading="loadingBaixarResultadoTeste"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-download
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <v-btn
                  color="#2B60D6"
                  min-width="0"
                  icon
                  fab
                  x-small
                  title="Exibir colunas"
                  @click="abreDialogExibirColunasMeses"
                >
                  <v-icon small>mdi-table-headers-eye</v-icon>
                </v-btn>
              </th>
              <th>ALOC_PERD</th>
              <th>SUBGRP_PRI</th>
              <th>SUBGRP_SEC</th>
              <fragment v-if="exibirColunasMeses.includes('ano')">
                <th>V_ENE_ANO</th>
                <th class="text-right">P_ENE_ANO (%)</th>
                <th class="text-right">ENE_ANO</th>
                <th class="text-right">ENE_TR_ANO</th>
              </fragment>
              <fragment
                v-for="mes in meses"
                :key="mes"
              >
                <fragment v-if="exibirColunasMeses.includes(mes)">
                  <th>V_ENE_{{ mes }}</th>
                  <th class="text-right">P_ENE_{{ mes }} (%)</th>
                  <th class="text-right">ENE_{{ mes }}</th>
                  <th class="text-right">ENE_TR_{{ mes }}</th>
                </fragment>
              </fragment>
              <th class="text-right">NUM_TRAFOS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td></td>
              <td>{{ item.aloc_perd }}</td>
              <td>{{ item.subgrp_pri }}</td>
              <td>{{ item.subgrp_sec }}</td>
              <fragment v-if="exibirColunasMeses.includes('ano')">
                <td
                  :class="{
                    'status-samp-ok':
                      item.v_ene_ano.trim().toUpperCase() === 'OK',
                    'status-samp-alerta': item.v_ene_ano
                      .trim()
                      .toUpperCase()
                      .includes('DIF'),
                    'status-samp-erro':
                      item.v_ene_ano.trim().toUpperCase() !== 'OK' &&
                      !item.v_ene_ano.trim().toUpperCase().includes('DIF')
                  }"
                >
                  {{ item.v_ene_ano | toUpperCase }}
                </td>
                <td class="text-right">
                  {{ item.p_ene_ano | parseNumberToFloatBR }}
                </td>
                <td class="text-right">
                  {{ item.ene_ano | parseNumberToFloatBR }}
                </td>
                <td class="text-right">
                  {{ item.ene_tr_ano | parseNumberToFloatBR }}
                </td>
              </fragment>
              <fragment
                v-for="mes in meses"
                :key="mes"
              >
                <fragment v-if="exibirColunasMeses.includes(mes)">
                  <td
                    :class="{
                      'status-samp-ok':
                        item[`v_ene_${mes}`].trim().toUpperCase() === 'OK',
                      'status-samp-alerta': item[`v_ene_${mes}`]
                        .trim()
                        .toUpperCase()
                        .includes('DIF'),
                      'status-samp-erro':
                        item[`v_ene_${mes}`].trim().toUpperCase() !== 'OK' &&
                        !item[`v_ene_${mes}`]
                          .trim()
                          .toUpperCase()
                          .includes('DIF')
                    }"
                  >
                    {{ item[`v_ene_${mes}`] | toUpperCase }}
                  </td>
                  <td class="text-right">
                    {{ item[`p_ene_${mes}`] | parseNumberToFloatBR }}
                  </td>
                  <td class="text-right">
                    {{ item[`ene_${mes}`] | parseNumberToFloatBR }}
                  </td>
                  <td class="text-right">
                    {{ item[`ene_tr_${mes}`] | parseNumberToFloatBR }}
                  </td>
                </fragment>
              </fragment>
              <td class="text-right">
                {{ item.num_trafos | parseNumberToIntegerBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="4"></th>
              <fragment v-if="exibirColunasMeses.includes('ano')">
                <th class="text-right">TOTAL</th>
                <td class="text-right">
                  {{
                    calculaPorcentagem('ene_ano', 'ene_tr_ano')
                      | parseNumberToFloatBR
                  }}
                </td>
                <td class="text-right">
                  {{ calculaSomatorio('ene_ano') | parseNumberToFloatBR }}
                </td>
                <td class="text-right">
                  {{ calculaSomatorio('ene_tr_ano') | parseNumberToFloatBR }}
                </td>
              </fragment>
              <fragment
                v-for="mes in meses"
                :key="mes"
              >
                <fragment v-if="exibirColunasMeses.includes(mes)">
                  <th class="text-right">TOTAL</th>
                  <td class="text-right">
                    {{
                      calculaPorcentagem(`ene_${mes}`, `ene_tr_${mes}`)
                        | parseNumberToFloatBR
                    }}
                  </td>
                  <td class="text-right">
                    {{ calculaSomatorio(`ene_${mes}`) | parseNumberToFloatBR }}
                  </td>
                  <td class="text-right">
                    {{
                      calculaSomatorio(`ene_tr_${mes}`) | parseNumberToFloatBR
                    }}
                  </td>
                </fragment>
              </fragment>
              <td class="text-right">
                {{ calculaSomatorio('num_trafos') | parseNumberToIntegerBR }}
              </td>
            </tr>
            <tr>
              <th :colspan="9 + exibirColunasMeses.length * 4"></th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <dialog-exibir-colunas-meses
      ref="dialogExibirColunasMeses"
      @exibirNovasColunasMeses="atualizaExibicaoColunasMeses"
    />
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
import CamposFiltros from '@/components/relatorios/CamposFiltros';
export default {
  name: 'TesteUntratXEpSigrMwh',
  mixins: [TabelaResultadoTesteSampMixins],
  components: {
    CamposFiltros
  },
  data: () => ({
    viewResultado: 'vw_untrat_x_ep_sigr_mwh'
  })
};
</script>
