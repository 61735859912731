<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-table-box-multiple-outline"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          BDGD
          <v-progress-circular
            v-if="loading"
            size="20"
            indeterminate
            color="rgba(0, 0, 0, 0.87)"
          />
          <span v-else>
            {{ bdgdVersao.ordinaria ? 'Ordinária' : '' }}
            {{ bdgdVersao.data_registro | formatToMonth }} v{{
              bdgdVersao.versao
            }}
          </span>
          x SAMP
        </div>
      </template>
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-row-reverse"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mt-n10"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  icon
                  color="black"
                  @click="downloadTestesSamp"
                  :loading="loadingDownloadTestesSamp"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="22"> mdi-download </v-icon>
                </v-btn>
              </div>
            </template>
            Baixar testes SAMP
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mt-n10"
                v-bind="attrs"
                v-on="on"
              >
                <v-btn
                  icon
                  color="black"
                  @click="dialogExecutarTestesSamp = true"
                  :loading="loadingExecucaoTestesSamp"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="22"> mdi-refresh </v-icon>
                </v-btn>
              </div>
            </template>
            Executar testes SAMP
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs
            centered
            v-model="tabsLevel1"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              href="#importacoes-samp"
              class="primary--text"
            >
              Importações SAMP
            </v-tab>
            <v-tab
              v-for="testeAneel in testesAneelAtivos"
              :key="testeAneel.slug"
              :value="testeAneel.slug"
              :href="`#${testeAneel.slug}`"
              class="primary--text"
            >
              {{ testeAneel.nome }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabsLevel1">
            <v-tab-item value="importacoes-samp">
              <v-row class="mt-4">
                <v-col cols="6">
                  <tabela-resumo-importacoes-bases-sisgd
                    class="mb-2"
                    :bdgdVersao="bdgdVersao"
                  />
                  <tabela-resumo-importacoes-bases-samp
                    :bdgdVersao="bdgdVersao"
                  />
                </v-col>
                <v-col cols="6">
                  <tabela-resumo-importacoes-bases-siga class="mb-2" />
                  <tabela-resumo-importacoes-bases-sigr
                    :bdgdVersao="bdgdVersao"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              v-for="testeAneel in testesAneelAtivos"
              :key="testeAneel.slug"
              :value="testeAneel.slug"
            >
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-alert
                    text
                    color="info"
                    v-if="testeAneel.descricao"
                  >
                    <v-row
                      align="center"
                      no-gutters
                    >
                      <v-col class="grow">
                        {{ testeAneel.descricao }}
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-progress-circular
                    v-if="loading"
                    size="20"
                    indeterminate
                    color="rgba(0, 0, 0, 0.87)"
                  />
                  <component
                    class="mt-4"
                    v-else
                    v-bind:is="getTabelaResultadoTesteAneel(testeAneel)"
                    :bdgdVersao="bdgdVersao"
                    :testeAneel="testeAneel"
                    :numExecucaoTestesSamp="numExecucaoTestesSamp"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="dialogExecutarTestesSamp"
      max-width="450px"
      @click:outside="dialogExecutarTestesSamp = false"
    >
      <v-card>
        <v-card-title class="headline-custom">
          Executar testes SAMP:
        </v-card-title>
        <v-card-text style="font-size: 15px !important">
          <v-radio-group
            v-model="executarAbasTestes"
            mandatory
            row
          >
            <v-radio
              label="Somente aba atual"
              value="ATUAL"
            />
            <v-radio
              label="Todas as abas"
              value="TODAS"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="dialogExecutarTestesSamp = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="executarTestesSamp"
          >
            Executar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ValidacoesSampService from '@/services/ValidacoesSampService';
import TabelaResumoImportacoesBasesSamp from '@/components/geracao-bdgd/samp/resumos-importacoes/TabelaResumoImportacoesBasesSamp.vue';
import TabelaResumoImportacoesBasesSiga from '@/components/geracao-bdgd/samp/resumos-importacoes/TabelaResumoImportacoesBasesSiga.vue';
import TabelaResumoImportacoesBasesSigr from '@/components/geracao-bdgd/samp/resumos-importacoes/TabelaResumoImportacoesBasesSigr.vue';
import TabelaResumoImportacoesBasesSisgd from '@/components/geracao-bdgd/samp/resumos-importacoes/TabelaResumoImportacoesBasesSisgd.vue';
import TesteBeSigrXSampAnoGwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteBeSigrXSampAnoGwh.vue';
import TesteEneXBeSigrAnoGwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteEneXBeSigrAnoGwh.vue';
import TesteCegSisgd from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteCegSisgd.vue';
import TesteCegSiga from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteCegSiga.vue';
import TesteUntratXEpSigrMwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteUntratXEpSigrMwh.vue';
import TesteBeSigrXSampMwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteBeSigrXSampMwh.vue';
import TesteEneXBeSigrMwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteEneXBeSigrMwh.vue';
import TesteEneSigrXEneSampMwh from '@/components/geracao-bdgd/samp/tabelas-resultados/TesteEneSigrXEneSampMwh.vue';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'ValidacoesSamp',
  mixins: [profilePermissionsMixin],
  components: {
    TabelaResumoImportacoesBasesSamp,
    TabelaResumoImportacoesBasesSiga,
    TabelaResumoImportacoesBasesSigr,
    TabelaResumoImportacoesBasesSisgd,
    TesteBeSigrXSampAnoGwh,
    TesteEneXBeSigrAnoGwh,
    TesteCegSisgd,
    TesteCegSiga,
    TesteUntratXEpSigrMwh,
    TesteBeSigrXSampMwh,
    TesteEneXBeSigrMwh,
    TesteEneSigrXEneSampMwh
  },
  data: () => ({
    tabsLevel1: null,
    loading: false,
    loadingDownloadTestesSamp: false,
    loadingExecucaoTestesSamp: false,
    testesAneelAtivos: [],
    numExecucaoTestesSamp: 0,
    dialogExecutarTestesSamp: false,
    executarAbasTestes: 'ATUAL'
  }),
  mounted() {
    this.getTestesAneelAtivos();
  },
  computed: {
    bdgdVersao() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    getTestesAneelAtivos() {
      this.loading = true;
      ValidacoesSampService.getTestesAneelAtivos()
        .then((response) => {
          this.testesAneelAtivos = response.data.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar os testes ANEEL ativos no sistema.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    },
    getTabelaResultadoTesteAneel(testeAneel) {
      return `Teste${testeAneel.classe}`;
    },
    executarTestesSamp() {
      this.dialogExecutarTestesSamp = false;
      this.loadingExecucaoTestesSamp = true;
      const executarTodosTestes = this.executarAbasTestes === 'TODAS';
      ValidacoesSampService.executarTestesSamp(this.bdgdVersao.id, {
        executarTodosTestes,
        testeSelecionado: executarTodosTestes ? null : this.tabsLevel1
      })
        .then(() => {
          this.$toast.success('Testes SAMP executados com sucesso.', '', {
            position: 'topRight'
          });
          this.numExecucaoTestesSamp++;
        })
        .catch(() => {
          this.$toast.error('Erro ao tentar executar os testes SAMP.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingExecucaoTestesSamp = false));
    },
    downloadTestesSamp() {
      this.loadingDownloadTestesSamp = true;
      ValidacoesSampService.baixarResultadoCompletoTestes(this.bdgdVersao.id, {
        timeout: 60 * 60 * 1000
      })
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `resultado-testes-samp-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo resultado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingDownloadTestesSamp = false));
    }
  }
};
</script>

<style>
.table-samp-result table thead tr th,
.table-samp-result table tbody tr td,
.table-samp-result table tfoot tr td {
  font-size: 12px;
  white-space: nowrap;
  padding: 0px 8px !important;
}
.table-samp-result table thead tr th {
  font-weight: 500;
}
.zebra1 {
  background-color: #fafafa;
}
.zebra2 {
  background-color: #f9fbe7;
}
.status-samp-ok {
  font-size: 8px !important;
  background-color: #c6efce;
  color: #006100;
}
.status-samp-erro {
  font-size: 8px !important;
  background: #ffc7ce;
  color: #9c0006;
}
.status-samp-alerta {
  font-size: 8px !important;
  background: #ffeb9c;
  color: #9c5700;
}
</style>
