import dayjs from 'dayjs';
import BasesDadosService from '@/services/BasesDadosService';
import ValidacoesSampService from '@/services/ValidacoesSampService';
import DialogExibirColunasMeses from './DialogExibirColunasMeses';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';

export default {
    components: {
      DialogExibirColunasMeses
    },
    mixins: [camposFiltrosMixins],
    props: {
      bdgdVersao: {
        type: Object,
        required: true
      },
      testeAneel: {
        type: Object,
        required: true
      },
      numExecucaoTestesSamp: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      loading: false,
      loadingBaixarResultadoTeste: false,
      mesesComErros: false,
      meses: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ],
      exibirColunasMeses: [
        'ano',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ],
      resultado: [],
      parametrosExtrasTeste: null,
      expandirTodasLinhas: false,
      expandirLinha: [],
      viewResultado: null,
      camposViewResultado: []
    }),
    mounted() {
      this.getResultadoTeste();
      this.getCamposViewResultado();
      this.getParametrosExtrasTeste();
    },
    computed: {
      bdgdVersaoId() {
        const { getUserSelectedCompanyBdgdVersion } = this.$store.getters;
        if (getUserSelectedCompanyBdgdVersion) {
          return getUserSelectedCompanyBdgdVersion.id;
        }
        return null;
      }
    },
    methods: {
      getResultadoTeste() {
        this.loading = true;
        let camposFiltros = null;
        const refCamposFiltros = this.$refs.refCamposFiltros;
        if (refCamposFiltros) camposFiltros = refCamposFiltros.exportCampos();
        ValidacoesSampService.getResultadoTeste(
          this.bdgdVersao.id,
          this.testeAneel.id,
          { 
            camposFiltros,
            mesesComErros: this.mesesComErros,
            mesesConsideradosAnalise: this.exibirColunasMeses
          }
        )
          .then((response) => {
            this.resultado = response.data;
          })
          .catch((err) => {
            console.log('Erro: ', err);
            this.$toast.error(
              `Erro ao buscar o resultado do teste SAMP ${this.testeAneel.nome}.`,
              '',
              {
                position: 'topRight'
              }
            );
          })
          .finally(() => (this.loading = false));
      },
      baixarResultadoTeste() {
        this.loadingBaixarResultadoTeste = true;
        let camposFiltros = null;
        const refCamposFiltros = this.$refs.refCamposFiltros;
        if (refCamposFiltros) camposFiltros = refCamposFiltros.exportCampos();
        ValidacoesSampService.baixarResultadoTeste(
          this.bdgdVersao.id,
          this.testeAneel.id,
          { 
            camposFiltros,
            mesesComErros: this.mesesComErros,
            mesesConsideradosAnalise: this.exibirColunasMeses
          },
          {
            timeout: 60 * 60 * 1000
          }
        )
        .then((res) => {
          const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
          const arquivo = `resultado-teste-${this.testeAneel.slug}-${timestamp}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            arquivo
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo resultado.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingBaixarResultadoTeste = false));
      },
      getCamposViewResultado() {
        if (!this.viewResultado) return;
        BasesDadosService.getBasesDadosCampos(this.viewResultado, this.bdgdVersaoId)
          .then((camposViewResultado) => {
            const ignorarCampos = this.getIgnorarCamposFiltros();
            this.camposViewResultado = camposViewResultado.filter(
              (campo) => !ignorarCampos.includes(campo.column)
            );
          })
          .catch((err) => {
            console.log('Erro: ', err);
            this.$toast.error(
              'Erro ao recuperar os campos da view selecionada.',
              '',
              { position: 'topRight' }
            );
          });
      },
      getParametrosExtrasTeste() {
        ValidacoesSampService.getParametrosExtrasTeste(
          this.bdgdVersao.id,
          this.testeAneel.id
        )
          .then((response) => {
            this.parametrosExtrasTeste = response.data;
          })
          .catch((err) => {
            console.log('Erro: ', err);
            this.$toast.error(
              `Erro ao buscar os parâmetros extras do teste SAMP ${this.testeAneel.nome}.`,
              '',
              {
                position: 'topRight'
              }
            );
          })
          .finally(() => (this.loading = false));
      },
      calculaPorcentagem(coluna1, coluna2) {
        const denominador = this.calculaSomatorio(coluna2);
        if (!denominador) return 100;
        const numerador = this.calculaSomatorio(coluna1);
        return 100 * (numerador / denominador);
      },
      calculaSomatorio(coluna) {
        return this.resultado.reduce(
          (acumulador, item) => (acumulador += Number(item[coluna])),
          0
        );
      },
      trataExpandirTodasLinhas() {
        this.expandirTodasLinhas = !this.expandirTodasLinhas;
        this.expandirLinha.forEach((item, indice) =>
          this.$set(this.expandirLinha, indice, false)
        );
      },
      trataExpandirLinha(indice) {
        if (this.expandirLinha[indice] === undefined) {
          this.$set(this.expandirLinha, indice, false);
        }
        this.$set(this.expandirLinha, indice, !this.expandirLinha[indice]);
      },
      abreDialogExibirColunasMeses() {
        const dialogExibirColunasMeses = this.$refs['dialogExibirColunasMeses'];
        dialogExibirColunasMeses.dialog = true;
      },
      atualizaExibicaoColunasMeses(exibirColunasMeses) {
        this.exibirColunasMeses = exibirColunasMeses;
      }
    },
    watch: {
      numExecucaoTestesSamp() {
        this.getResultadoTeste();
      }
    }
  };