<template>
  <div>
    <v-row align="center">
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          :baseDados="viewResultado"
          :campos="camposViewResultado"
        />
      </v-col>
    </v-row>
    <v-row
      class="mb-10"
      align="center"
    >
      <v-col cols="2">
        <v-switch
          v-model="mesesComErros"
          hide-details
          inset
          label="Meses com erros"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          small
          title="Atualizar o resultado utilizando os filtros selecionados"
          outlined
          class="mr-2"
          @click="getResultadoTeste"
          :loading="loading"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn
          small
          title="Baixar o resultado utilizando os filtros selecionados"
          outlined
          @click="baixarResultadoTeste"
          :loading="loadingBaixarResultadoTeste"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-download
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-right">
                <v-btn
                  color="#2B60D6"
                  min-width="0"
                  icon
                  fab
                  x-small
                  title="Exibir colunas"
                  @click="abreDialogExibirColunasMeses"
                >
                  <v-icon small>mdi-table-headers-eye</v-icon>
                </v-btn>
                <v-btn
                  color="#2B60D6"
                  min-width="0"
                  icon
                  fab
                  x-small
                  @click="trataExpandirTodasLinhas"
                >
                  <v-icon
                    small
                    v-if="expandirTodasLinhas"
                  >
                    mdi-minus
                  </v-icon>
                  <v-icon
                    small
                    v-else
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </th>
              <th>ORG_ENER</th>
              <th>SUB_GRP</th>
              <fragment v-if="exibirColunasMeses.includes('ano')">
                <th>V_ENE_ANO</th>
                <th class="text-right">P_ENE_ANO (%)</th>
                <th class="text-right">ENE_ANO_SAMP</th>
                <th class="text-right">ENE_ANO_SIGR</th>
              </fragment>
              <fragment
                v-for="mes in meses"
                :key="mes"
              >
                <fragment v-if="exibirColunasMeses.includes(mes)">
                  <th>V_ENE_{{ mes }}</th>
                  <th class="text-right">P_ENE_{{ mes }} (%)</th>
                  <th class="text-right">ENE_{{ mes }}_SAMP</th>
                  <th class="text-right">ENE_{{ mes }}_SIGR</th>
                </fragment>
              </fragment>
            </tr>
          </thead>
          <tbody>
            <fragment
              v-for="(item, j) in resultado"
              :key="j"
            >
              <tr :class="j % 2 == 1 ? 'zebra1' : ''">
                <td class="text-right">
                  <v-btn
                    color="#2B60D6"
                    min-width="0"
                    icon
                    fab
                    x-small
                    @click="() => trataExpandirLinha(j)"
                  >
                    <v-icon
                      small
                      v-if="expandirLinha[j] || expandirTodasLinhas"
                    >
                      mdi-minus
                    </v-icon>
                    <v-icon
                      small
                      v-else
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </td>
                <td title="ORG_ENER">{{ item.org_ener }}</td>
                <td></td>
                <fragment v-if="exibirColunasMeses.includes('ano')">
                  <td
                    :title="`V_ENE_ANO\nORG_ENER: ${item.org_ener}`"
                    :class="{
                      'status-samp-ok':
                        item.v_ene_ano.trim().toUpperCase() === 'OK',
                      'status-samp-alerta': item.v_ene_ano
                        .trim()
                        .toUpperCase()
                        .includes('DIF'),
                      'status-samp-erro':
                        item.v_ene_ano.trim().toUpperCase() !== 'OK' &&
                        !item.v_ene_ano.trim().toUpperCase().includes('DIF')
                    }"
                  >
                    {{ item.v_ene_ano | toUpperCase }}
                  </td>
                  <td
                    class="text-right"
                    :title="`P_ENE_ANO (%)\nORG_ENER: ${item.org_ener}`"
                  >
                    {{ item.p_ene_ano | parseNumberToFloatBR }}
                  </td>
                  <td
                    class="text-right"
                    :title="`ENE_ANO_SAMP\nORG_ENER: ${item.org_ener}`"
                  >
                    {{ item.ene_ano_samp | parseNumberToFloatBR }}
                  </td>
                  <td
                    class="text-right"
                    :title="`ENE_ANO_SIGR\nORG_ENER: ${item.org_ener}`"
                  >
                    {{ item.ene_ano_sigr | parseNumberToFloatBR }}
                  </td>
                </fragment>
                <fragment
                  v-for="mes in meses"
                  :key="mes"
                >
                  <fragment v-if="exibirColunasMeses.includes(mes)">
                    <td
                      :title="`V_ENE_${mes}\nORG_ENER: ${item.org_ener}`"
                      :class="{
                        'status-samp-ok':
                          item[`v_ene_${mes}`].trim().toUpperCase() === 'OK',
                        'status-samp-alerta': item[`v_ene_${mes}`]
                          .trim()
                          .toUpperCase()
                          .includes('DIF'),
                        'status-samp-erro':
                          item[`v_ene_${mes}`].trim().toUpperCase() !== 'OK' &&
                          !item[`v_ene_${mes}`]
                            .trim()
                            .toUpperCase()
                            .includes('DIF')
                      }"
                    >
                      {{ item[`v_ene_${mes}`] | toUpperCase }}
                    </td>
                    <td
                      class="text-right"
                      :title="`P_ENE_${mes} (%)\nORG_ENER: ${item.org_ener}`"
                    >
                      {{ item[`p_ene_${mes}`] | parseNumberToFloatBR }}
                    </td>
                    <td
                      class="text-right"
                      :title="`ENE_${mes}_SAMP\nORG_ENER: ${item.org_ener}`"
                    >
                      {{ item[`ene_${mes}_samp`] | parseNumberToFloatBR }}
                    </td>
                    <td
                      class="text-right"
                      :title="`ENE_${mes}_SIGR\nORG_ENER: ${item.org_ener}`"
                    >
                      {{ item[`ene_${mes}_sigr`] | parseNumberToFloatBR }}
                    </td>
                  </fragment>
                </fragment>
              </tr>
              <fragment v-if="expandirLinha[j] || expandirTodasLinhas">
                <fragment
                  v-for="(linha, k) in item.com_sub_grupo"
                  :key="k"
                >
                  <tr :class="k % 2 == 1 ? '' : 'zebra2'">
                    <td colspan="2"></td>
                    <td title="SUB_GRP">
                      {{ linha.sub_grp }}
                    </td>
                    <fragment v-if="exibirColunasMeses.includes('ano')">
                      <td
                        :title="`V_ENE_ANO\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                        :class="{
                          'status-samp-ok':
                            linha.v_ene_ano.trim().toUpperCase() === 'OK',
                          'status-samp-alerta': linha.v_ene_ano
                            .trim()
                            .toUpperCase()
                            .includes('DIF'),
                          'status-samp-erro':
                            linha.v_ene_ano.trim().toUpperCase() !== 'OK' &&
                            !linha.v_ene_ano
                              .trim()
                              .toUpperCase()
                              .includes('DIF')
                        }"
                      >
                        {{ linha.v_ene_ano | toUpperCase }}
                      </td>
                      <td
                        class="text-right"
                        :title="`P_ENE_ANO (%)\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                      >
                        {{ linha.p_ene_ano | parseNumberToFloatBR }}
                      </td>
                      <td
                        class="text-right"
                        :title="`ENE_ANO_SAMP\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                      >
                        {{ linha.ene_ano_samp | parseNumberToFloatBR }}
                      </td>
                      <td
                        class="text-right"
                        :title="`ENE_ANO_SIGR\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                      >
                        {{ linha.ene_ano_sigr | parseNumberToFloatBR }}
                      </td>
                    </fragment>
                    <fragment
                      v-for="mes in meses"
                      :key="mes"
                    >
                      <fragment v-if="exibirColunasMeses.includes(mes)">
                        <td
                          :title="`V_ENE_${mes}\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                          :class="{
                            'status-samp-ok':
                              linha[`v_ene_${mes}`].trim().toUpperCase() ===
                              'OK',
                            'status-samp-alerta': linha[`v_ene_${mes}`]
                              .trim()
                              .toUpperCase()
                              .includes('DIF'),
                            'status-samp-erro':
                              linha[`v_ene_${mes}`].trim().toUpperCase() !==
                                'OK' &&
                              !linha[`v_ene_${mes}`]
                                .trim()
                                .toUpperCase()
                                .includes('DIF')
                          }"
                        >
                          {{ linha[`v_ene_${mes}`] | toUpperCase }}
                        </td>
                        <td
                          class="text-right"
                          :title="`P_ENE_${mes} (%)\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                        >
                          {{ linha[`p_ene_${mes}`] | parseNumberToFloatBR }}
                        </td>
                        <td
                          class="text-right"
                          :title="`ENE_${mes}_SAMP\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                        >
                          {{ linha[`ene_${mes}_samp`] | parseNumberToFloatBR }}
                        </td>
                        <td
                          class="text-right"
                          :title="`ENE_${mes}_SIGR\nORG_ENER: ${linha.org_ener}\nSUB_GRP: ${linha.sub_grp}`"
                        >
                          {{ linha[`ene_${mes}_sigr`] | parseNumberToFloatBR }}
                        </td>
                      </fragment>
                    </fragment>
                  </tr>
                </fragment>
              </fragment>
            </fragment>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="3"></th>
              <fragment v-if="exibirColunasMeses.includes('ano')">
                <th class="text-right">TOTAL</th>
                <td
                  class="text-right"
                  title="P_ENE_ANO (%)"
                >
                  {{
                    calculaPorcentagem('ene_ano_samp', 'ene_ano_sigr')
                      | parseNumberToFloatBR
                  }}
                </td>
                <td
                  class="text-right"
                  title="ENE_ANO_SAMP"
                >
                  {{ calculaSomatorio('ene_ano_samp') | parseNumberToFloatBR }}
                </td>
                <td
                  class="text-right"
                  title="ENE_ANO_SIGR"
                >
                  {{ calculaSomatorio('ene_ano_sigr') | parseNumberToFloatBR }}
                </td>
              </fragment>
              <fragment
                v-for="mes in meses"
                :key="mes"
              >
                <fragment v-if="exibirColunasMeses.includes(mes)">
                  <th class="text-right">TOTAL</th>
                  <td
                    class="text-right"
                    :title="`P_ENE_${mes} (%)`"
                  >
                    {{
                      calculaPorcentagem(`ene_${mes}_samp`, `ene_${mes}_sigr`)
                        | parseNumberToFloatBR
                    }}
                  </td>
                  <td
                    class="text-right"
                    :title="`ENE_${mes}_SAMP`"
                  >
                    {{
                      calculaSomatorio(`ene_${mes}_samp`) | parseNumberToFloatBR
                    }}
                  </td>
                  <td
                    class="text-right"
                    :title="`ENE_${mes}_SIGR`"
                  >
                    {{
                      calculaSomatorio(`ene_${mes}_sigr`) | parseNumberToFloatBR
                    }}
                  </td>
                </fragment>
              </fragment>
            </tr>
            <tr>
              <th :colspan="7 + exibirColunasMeses.length * 4"></th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <dialog-exibir-colunas-meses
      ref="dialogExibirColunasMeses"
      @exibirNovasColunasMeses="atualizaExibicaoColunasMeses"
    />
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
import CamposFiltros from '@/components/relatorios/CamposFiltros';
export default {
  name: 'TesteBeSigrXSampMwh',
  mixins: [TabelaResultadoTesteSampMixins],
  components: {
    CamposFiltros
  },
  data: () => ({
    viewResultado: 'vw_be_sigr_x_samp_mwh_com_sub_grp'
  })
};
</script>
