<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="280px"
    >
      <v-card>
        <v-card-title>
          <v-switch
            v-model="exibirTodasColunas"
            hide-details
            inset
            label="Exibir todas colunas"
          />
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-switch
            v-model="exibirColunasMeses"
            hide-details
            inset
            label="Exibir ano"
            value="ano"
          />
          <v-switch
            v-model="exibirColunasMeses"
            hide-details
            inset
            v-for="mes in meses"
            :key="mes"
            :label="`Exibir mês ${mes}`"
            :value="mes"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="aplicar"
          >
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DialogExibirColunasMeses',
  data: () => ({
    dialog: false,
    exibirTodasColunas: true,
    meses: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ],
    exibirColunasMeses: [],
    todasColunas: [
      'ano',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ]
  }),
  mounted() {
    this.exibirColunasMeses = this.todasColunas;
    this.$emit('exibirNovasColunasMeses', this.exibirColunasMeses);
  },
  methods: {
    aplicar() {
      this.dialog = false;
      this.$emit('exibirNovasColunasMeses', this.exibirColunasMeses);
    }
  },
  watch: {
    exibirTodasColunas() {
      if (this.exibirTodasColunas) {
        this.exibirColunasMeses = this.todasColunas;
      } else {
        this.exibirColunasMeses = [];
      }
    }
  }
};
</script>
