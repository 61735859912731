<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>ENTIDADE</th>
              <th class="text-right">QTDE_CEG_SISGD</th>
              <th class="text-right">QTDE_CEG_BDGD</th>
              <th>STATUS</th>
              <th class="text-right">ND_BDGD</th>
              <th class="text-right">REPETIDOS</th>
              <th class="text-right">CONCILIADOS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.tabela | toUpperCase }}</td>
              <td class="text-right">
                {{ item.qtd_ceg_sisgd | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ item.qtd_ceg_bdgd | parseNumberToIntegerBR }}
              </td>
              <td
                :class="{
                  'status-samp-ok':
                    item.status.trim().toUpperCase() === 'CONCILIADOS',
                  'status-samp-erro':
                    item.status.trim().toUpperCase() !== 'CONCILIADOS'
                }"
              >
                {{ item.status | toUpperCase }}
              </td>
              <td class="text-right">
                {{ item.nd_bdgd | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ item.repetidos | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ item.conciliado | parseNumberToIntegerBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="2"
                class="text-right"
                title="QTDE_CEG_SISGD"
              >
                <span class="texto-total">TOTAL</span>
                {{ calculaSomatorio('qtd_ceg_sisgd') | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                title="QTDE_CEG_BDGD"
              >
                {{ calculaSomatorio('qtd_ceg_bdgd') | parseNumberToIntegerBR }}
              </td>
              <td
                colspan="2"
                class="text-right"
                title="ND_BDGD"
              >
                {{ calculaSomatorio('nd_bdgd') | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                title="REPETIDOS"
              >
                {{ calculaSomatorio('repetidos') | parseNumberToIntegerBR }}
              </td>
              <td
                class="text-right"
                title="CONCILIADOS"
              >
                {{ calculaSomatorio('conciliado') | parseNumberToIntegerBR }}
              </td>
            </tr>
            <tr>
              <td colspan="7"></td>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th class="text-right">% NÃO CONCILIADOS</th>
              <td
                class="text-right"
                style="font-size: 10px !important"
                :class="{
                  'status-samp-ok':
                    percNaoConciliado <=
                    parametrosExtrasTeste.percNaoConciliadoLimite,
                  'status-samp-erro':
                    percNaoConciliado >
                    parametrosExtrasTeste.percNaoConciliadoLimite
                }"
              >
                {{ percNaoConciliado | parseNumberToFloatBR }}%
              </td>
              <th
                colspan="3"
                class="text-right"
              >
                <v-btn
                  small
                  title="Baixar o resultado"
                  outlined
                  @click="baixarResultadoTeste"
                  :loading="loadingBaixarResultadoTeste"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th class="text-right">% REPETIDOS</th>
              <td
                class="text-right"
                style="font-size: 10px !important"
                :class="{
                  'status-samp-ok':
                    percRepetidos <= parametrosExtrasTeste.percRepetidosLimite,
                  'status-samp-erro':
                    percRepetidos > parametrosExtrasTeste.percRepetidosLimite
                }"
              >
                {{ percRepetidos | parseNumberToFloatBR }}%
              </td>
              <th colspan="3"></th>
            </tr>
            <tr>
              <th colspan="2"></th>
              <th class="text-right">% BDGD</th>
              <td
                class="text-right"
                style="font-size: 10px !important"
                :class="{
                  'status-samp-ok':
                    percBdgd <= parametrosExtrasTeste.percBdgdLimite,
                  'status-samp-erro':
                    percBdgd > parametrosExtrasTeste.percBdgdLimite
                }"
              >
                {{ percBdgd | parseNumberToFloatBR }}%
              </td>
              <th colspan="3"></th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
export default {
  name: 'TesteCegSisgd',
  mixins: [TabelaResultadoTesteSampMixins],
  data: () => ({
    parametrosExtrasTeste: {
      percNaoConciliadoLimite: 0,
      percRepetidosLimite: 0,
      percBdgdLimite: 0
    }
  }),
  computed: {
    percNaoConciliado() {
      return this.calculaPorcentagem('nd_bdgd', 'qtd_ceg_sisgd');
    },
    percRepetidos() {
      return this.calculaPorcentagem('repetidos', 'qtd_ceg_sisgd');
    },
    percBdgd() {
      return this.calculaPorcentagem('qtd_ceg_bdgd', 'qtd_ceg_sisgd');
    }
  }
};
</script>

<style scoped>
.texto-total {
  font-weight: 500;
  margin-right: 10px;
}
</style>
