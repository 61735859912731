var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_c('div',[_c('v-simple-table',{staticClass:"table-samp-result",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("GRU_TEN")]),_c('th',[_vm._v("STATUS")]),_c('th',{staticClass:"text-right"},[_vm._v("QTDE_UG_BDGD")]),_c('th',{staticClass:"text-right"},[_vm._v("QTDE_UG_BIG")])])]),_c('tbody',_vm._l((_vm.resultado),function(item,j){return _c('tr',{key:j,class:j % 2 === 1 ? 'zebra1' : ''},[_c('td',[_vm._v(_vm._s(_vm._f("toUpperCase")(item.gru_ten)))]),_c('td',{class:{
                'status-samp-ok':
                  item.status.trim().toUpperCase() === 'CONCILIADOS',
                'status-samp-erro':
                  item.status.trim().toUpperCase() !== 'CONCILIADOS'
              }},[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(item.status))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.qtd_ug_bdgd))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.qtd_ug_big))+" ")])])}),0),_c('tfoot',[_c('tr',[_c('th',{attrs:{"colspan":"4"}})]),_c('tr',[_c('th',{staticClass:"text-right"},[_vm._v("% CONCILIADO")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok': _vm.percConciliado >= 100,
                'status-samp-erro': _vm.percConciliado < 100
              },staticStyle:{"font-size":"10px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.percConciliado))+"% ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.totalQtdUgBdgd))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegeBR")(_vm.totalQtdUgBig))+" ")])]),_c('tr',[_c('th',{staticClass:"text-right",attrs:{"colspan":"4"}},[_c('v-btn',{attrs:{"small":"","title":"Baixar o resultado","outlined":"","loading":_vm.loadingBaixarResultadoTeste},on:{"click":_vm.baixarResultadoTeste}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-download ")])],1)],1)])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }