<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>BASE SIGA</th>
              <th class="text-right">LINHAS IMPORTADAS</th>
              <th class="text-right">IMPORTADAS EM</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.data_registro | formatToMonth }}</td>
              <td class="text-right">
                {{ item.total_linhas_importadas | parseNumberToIntegerBR }}
              </td>
              <td class="text-right">
                {{ item.importado_em | parseToDateTimeBR }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import ValidacoesSampService from '@/services/ValidacoesSampService';

export default {
  name: 'TabelaResumoImportacoesBasesSiga',
  data: () => ({
    loading: false,
    resultado: []
  }),
  mounted() {
    this.getResumoImportacoesBasesSiga();
  },
  methods: {
    getResumoImportacoesBasesSiga() {
      this.loading = true;
      ValidacoesSampService.getResumoImportacoesBasesSiga()
        .then((response) => {
          this.resultado = response.data;
        })
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error(
            'Erro ao buscar o resumo de importações das bases SIGA.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
