var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_c('div',[_c('v-simple-table',{staticClass:"table-samp-result",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("ENTIDADE")]),_c('th',{staticClass:"text-right"},[_vm._v("QTDE_CEG_SISGD")]),_c('th',{staticClass:"text-right"},[_vm._v("QTDE_CEG_BDGD")]),_c('th',[_vm._v("STATUS")]),_c('th',{staticClass:"text-right"},[_vm._v("ND_BDGD")]),_c('th',{staticClass:"text-right"},[_vm._v("REPETIDOS")]),_c('th',{staticClass:"text-right"},[_vm._v("CONCILIADOS")])])]),_c('tbody',_vm._l((_vm.resultado),function(item,j){return _c('tr',{key:j,class:j % 2 === 1 ? 'zebra1' : ''},[_c('td',[_vm._v(_vm._s(_vm._f("toUpperCase")(item.tabela)))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.qtd_ceg_sisgd))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.qtd_ceg_bdgd))+" ")]),_c('td',{class:{
                'status-samp-ok':
                  item.status.trim().toUpperCase() === 'CONCILIADOS',
                'status-samp-erro':
                  item.status.trim().toUpperCase() !== 'CONCILIADOS'
              }},[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(item.status))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.nd_bdgd))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.repetidos))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.conciliado))+" ")])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"2","title":"QTDE_CEG_SISGD"}},[_c('span',{staticClass:"texto-total"},[_vm._v("TOTAL")]),_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.calculaSomatorio('qtd_ceg_sisgd')))+" ")]),_c('td',{staticClass:"text-right",attrs:{"title":"QTDE_CEG_BDGD"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.calculaSomatorio('qtd_ceg_bdgd')))+" ")]),_c('td',{staticClass:"text-right",attrs:{"colspan":"2","title":"ND_BDGD"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.calculaSomatorio('nd_bdgd')))+" ")]),_c('td',{staticClass:"text-right",attrs:{"title":"REPETIDOS"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.calculaSomatorio('repetidos')))+" ")]),_c('td',{staticClass:"text-right",attrs:{"title":"CONCILIADOS"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.calculaSomatorio('conciliado')))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"7"}})]),_c('tr',[_c('th',{attrs:{"colspan":"2"}}),_c('th',{staticClass:"text-right"},[_vm._v("% NÃO CONCILIADOS")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok':
                  _vm.percNaoConciliado <=
                  _vm.parametrosExtrasTeste.percNaoConciliadoLimite,
                'status-samp-erro':
                  _vm.percNaoConciliado >
                  _vm.parametrosExtrasTeste.percNaoConciliadoLimite
              },staticStyle:{"font-size":"10px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.percNaoConciliado))+"% ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"3"}},[_c('v-btn',{attrs:{"small":"","title":"Baixar o resultado","outlined":"","loading":_vm.loadingBaixarResultadoTeste},on:{"click":_vm.baixarResultadoTeste}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-download ")])],1)],1)]),_c('tr',[_c('th',{attrs:{"colspan":"2"}}),_c('th',{staticClass:"text-right"},[_vm._v("% REPETIDOS")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok':
                  _vm.percRepetidos <= _vm.parametrosExtrasTeste.percRepetidosLimite,
                'status-samp-erro':
                  _vm.percRepetidos > _vm.parametrosExtrasTeste.percRepetidosLimite
              },staticStyle:{"font-size":"10px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.percRepetidos))+"% ")]),_c('th',{attrs:{"colspan":"3"}})]),_c('tr',[_c('th',{attrs:{"colspan":"2"}}),_c('th',{staticClass:"text-right"},[_vm._v("% BDGD")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok':
                  _vm.percBdgd <= _vm.parametrosExtrasTeste.percBdgdLimite,
                'status-samp-erro':
                  _vm.percBdgd > _vm.parametrosExtrasTeste.percBdgdLimite
              },staticStyle:{"font-size":"10px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.percBdgd))+"% ")]),_c('th',{attrs:{"colspan":"3"}})])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }