var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_c('div',[_c('v-simple-table',{staticClass:"table-samp-result",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("BASE SIG-R")]),_c('th',[_vm._v("MÊS")]),_c('th',{staticClass:"text-right"},[_vm._v("COM ENERGIA")]),_c('th',{staticClass:"text-right"},[_vm._v("SEM ENERGIA")]),_c('th',{staticClass:"text-right"},[_vm._v("IMPORTADAS EM")])])]),_c('tbody',_vm._l((_vm.resultado),function(item,j){return _c('tr',{key:j,class:j % 2 === 1 ? 'zebra1' : ''},[_c('td',[_vm._v(_vm._s(item.base))]),_c('td',[_vm._v(_vm._s(_vm._f("addLeadingZero")(item.mes)))]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok': item.total_linhas_energias_nao_zeradas > 0,
                'status-samp-erro':
                  item.total_linhas_energias_nao_zeradas <= 0
              },staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.total_linhas_energias_nao_zeradas))+" ")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-alerta': item.total_linhas_energias_zeradas > 0,
                'status-samp-ok': item.total_linhas_energias_zeradas <= 0
              },staticStyle:{"font-size":"12px !important"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.total_linhas_energias_zeradas))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.importado_em))+" ")])])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }