<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>ORG</th>
              <th>V_ENE_ANO</th>
              <th class="text-right">P_ENE_ANO (%)</th>
              <th class="text-right">ENE_ANO_SAMP_GWH</th>
              <th class="text-right">ENE_ANO_SIGR_GWH</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.org }}</td>
              <td
                :class="{
                  'status-samp-ok':
                    item.v_ene_ano.trim().toUpperCase() === 'OK',
                  'status-samp-alerta':
                    item.v_ene_ano.trim().toUpperCase() !== 'OK'
                }"
              >
                {{ item.v_ene_ano | toUpperCase }}
              </td>
              <td class="text-right">
                {{ item.p_ene_ano | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ item.ene_ano_samp_gwh | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ item.ene_ano_sigr_gwh | parseNumberToFloatBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th class="text-right">TOTAL</th>
              <td class="text-right">--</td>
              <td class="text-right">
                {{ totalEneAnoSampGwh | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ totalEneAnoSigrGwh | parseNumberToFloatBR }}
              </td>
            </tr>
            <tr>
              <th></th>
              <th class="text-right">DIF GWH BE SAMP</th>
              <td class="text-right">
                {{ percDifEneAnoSampXSigrGwh | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ difEneAnoSampXSigrGwh | parseNumberToFloatBR }}
              </td>
              <th class="text-right">
                <v-btn
                  small
                  title="Baixar o resultado"
                  outlined
                  @click="baixarResultadoTeste"
                  :loading="loadingBaixarResultadoTeste"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
export default {
  name: 'TesteBeSigrXSampAnoGwh',
  mixins: [TabelaResultadoTesteSampMixins],
  computed: {
    totalEneAnoSampGwh() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.ene_ano_samp_gwh)),
        0
      );
    },
    totalEneAnoSigrGwh() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.ene_ano_sigr_gwh)),
        0
      );
    },
    percDifEneAnoSampXSigrGwh() {
      if (this.totalEneAnoSigrGwh === 0) return 100;
      return (this.totalEneAnoSampGwh / this.totalEneAnoSigrGwh) * 100;
    },
    difEneAnoSampXSigrGwh() {
      return this.totalEneAnoSampGwh - this.totalEneAnoSigrGwh;
    }
  }
};
</script>
